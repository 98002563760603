@import url("../base.css");


main{
  margin-top: 100px; 
}

.ng-binding{
  font-size: 16px; color: rbg(31,31,31); 
}
h2{
  color: black; font-size: 31px; font-family: var(--Gilroy-Bold);
  width: 100%; max-width: 588px; margin: auto; margin-bottom: 20px;
}

.signup-form{
  border-radius: 10px; padding: 32px; margin: 0 0 16px 0;
  width: 100%; max-width: 588px; box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.15);
  background-color: #fff; min-height: 158px;
  color: #000; margin: auto; margin-bottom: 20px;
  position: relative;
  /*
  font: normal 400 16px/27.2px var(--font-family);
  */
}
.country-question{
  font-size: 16px; font-family: var(--Helvetica);
  font-weight: 600; margin-bottom: 15px;
}

/* Styles for the dropdown container */
.country-selection{
  position: relative; margin-bottom: 30px;
}
.country-wrapper label{
  width: 95%;
  display: flex; justify-content: space-between;
  align-items: center; background: none;
}
.country-wrapper input{
  background: none; 
  position: absolute; left: 0;

}
.country-selected{
  width: 100%; height: 100%;
  position: relative;
}
.sub-form{
  /*
  */
  display: none
}


/*dropdown*/
.search-input-wrapper{
  display: flex;
  align-items: center; padding: 5px 10px 10px 10px;
  gap: 5px; 
  border-bottom:  1px solid var(--bs-gray-300);
}
.search-input-wrapper div{
  background: url("../component_media/search_icon.svg") center/contain no-repeat;
  width: 15px; height: 15px;
}
.dropdown-wrapper{
  width: 100%; position: absolute; 
  border: 1px solid #ccc;
  background: #FFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 10px;
  margin-top: -5px;
  border-radius: 5px;
  z-index: 1;
  display: none;
}
.country-list{
  max-height: 300px;
  overflow-y: auto; 
  list-style-type: none;
  padding: 0px; margin: 0px;
  left: 0;
  width: 100%;
  background-color: #fff;
}

/* Styles for dropddown list items */
.country-list li {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  display: flex; align-items: center; gap: 15px;
  list-style-type: none;
}
.country-list li>div {
  width: 29px; height: 21px; border: 1px solid; 
}

/* Styles for the list items on hover */
.country-list li:hover {
  background-color: #f0f0f0;
}

.already-have-an-account,.log-in{
  text-align: center; font-size: 16px;
}
.already-have-an-account{
  margin-bottom: 10px;
}

.input-wrapper{
  border: 1px solid var(--input-wrapper-border2); height: 55px;
  position: relative; padding: 5px 18px; display: flex;
  align-items: center; margin-bottom: 10px;
}
.input-wrapper>label{
  position: absolute; font-family: var(--Helvetica);
  font-size: 16px; color: rgb(56,64,72); top: 20px; left: 10px
}
legend{
  font-size: 13px; color: var(--gray); padding: 0px 5px 0px 5px; 
  display:none;
}

.input-guide,.input-error{
  padding-left: 20px; font-family: var(--Helvetica);
  font-size: 13px; 
}
.input-error{
  display: none;
}
.input-guide{
  color: #5e6368;
  margin-bottom: 5px;
}

.firstname-wrapper{
  margin-bottom: 30px;
}
.fullname-wrapper{
  margin-bottom: 30px;
}
.password-wrapper{
  margin-bottom: 50px;
}


.validation-list{
  padding: 0px 20px; font-size: 14px;
}
.signup-button{
  color: black; font-size: 20px; 
}

.signup-button+p{
  font-size: 13px; color: #5e6368
}

.signup-footer-note{
  background-color: #1F1F1F; margin-top: 50px;
  color: #BFBFC0;
  font: normal 400 12px/18px var(--Helvetica);
  padding: 32px 15px 64px; 
}
/*
.checklist-wrapper li::before {
  content: '\2713'; /* Unicode checkmark character 
  margin-right: 5px; /* Adjust spacing 
}
*/
/*----------email verification code-----------------*/
.email-verification-code{
  display: none;
}
.evc-heading{/*email verification code*/
  font-size: 16px; font-family: var(--Helvetica);
  font-weight: 600; margin-bottom: 5px;
}
.evc-subheading{
  color: var(--bs-gray-900); margin-bottom: 20px;
  font-size: 15px;
}
.evc-subheading>strong{
  color: var(--bs-gray-800);
}
.evc-subheading>span{
  font-size: 15px; color: #43024a;
}
.email-code-input-wrapper{
  margin-bottom: 50px;
}
.email-code-input-error{
  position: relative; 
  top: -35px
}



@media screen and (min-width:500px){
    /* -----------top bar--------------- */
    .fullname-wrapper{
      display: flex; justify-content: space-between; gap: 20px;
    }
    .firstname-wrapper{
      margin-bottom: 0px;
    }
}