@import url("../base.css");

.support-post-header{
  margin-top: 100px;
  position: relative;
}
.support-hero-container{
  height: 300px; 
  background: url("../component_media/support_hero_img.jpg") center/cover no-repeat; 
}
.support-means-wrapper{
  padding: 30px; display: flex; margin-bottom: 50px;
  justify-content: space-evenly; flex-wrap: wrap;
}
.support-box{
  border: 1px solid #ddd; margin-top: 30px;
  display: flex; flex-direction: column;
  justify-content: center; align-items: center;
  padding: 20px; width: 100%; max-width: 500px; border-radius: 5px;
  box-shadow: 0 4px 20px rgba(0,0,0,.1); background: white;
}
.support-box a{
  text-decoration: none
}
.footer-note{
  padding: 30px;
  border-top: 1px solid #ddd;
  color: #333333; font-size: 15px;
  font-weight: 400;
  line-height: 1.5
}