@import url("../base.css");
/* font: font-style font-weight font-size/line-height font-family*/
.main{
  padding: 20px;
}
.kyc-pane{
  margin-top: 100px
}
.kyc-interations{
 background: #FFF; border-radius: 10px;
  width: 100%; max-width: 600px
}
.kyc-note{
  display: none;
}

.kcd{/*kyc custom dropdown*/
  margin-bottom: 30px
}
.kdt {/*kyc custom dropdown toggle*/
    background-color: rgba(0,0,0,0);
    color: var(--bs-gray-800);
    font-size: 14px;
    border: none;
    cursor: pointer;
    outline: none;
    display: flex; justify-content: space-between;
    align-items: center;
    width: 100%; margin-bottom: 5px; height: 50px;
}
.kdtt{/*kyc dropdown toggle title*/
  font: normal 400 12px/18px var(--Helvetica);
  text-align: left; width: 70%
}
.kdti-wrapper{
  display: flex; align-items: center;
}
.kyc-counter{
  border: 4px solid #e6e6e6; 
  width: 40px; height: 40px; 
  border-radius: 50%; display: flex;
  justify-content: center; align-items: center;
}
.kdti{/*new-dropdown*//*kyc dropdown toggle icon*/
    background: url("../component_media/dropdown_icon.svg") center/cover no-repeat; 
    width: 20px; height: 20px; 
}
.kde {/*kyc dropdown entries*/
  /*
  */
    display: none;
    border-top: none;
    border-radius: 0 0 4px 4px;
    z-index: 1;
    width: 100%;
    padding: 0px;
}

.kde-entre {/*new dropdown*//*kyc dropdown entre*/
  color: #000; list-style-type: none;
  text-decoration: none;
  padding: 15px 10px; background: #FFF;
  border: 1px solid #00AFAB; 
  border-radius: 5px;
  margin-bottom: 10px; display: flex; justify-content: space-between;
  align-items: center;
}
.kde-entre-status{
  width: 20px; /* Adjust the width and height based on your design */
  height: 20px;
  border: 2px solid #e6ecf5; /* Green border color */
  border-radius: 50%;
  background-color: #fff; /* White background color */
  /*center content in the element*/
  display: flex; text-align: center; justify-content: center;
}
.kde-entre--disabled{
  background: rgba(0,0,0,0);
  border: 1px solid var(--bs-gray-300);
  color: var(--bs-gray-400); cursor: not-allowed;
}
.kde-entre--disabled .kde-entre-status{
  background: var(--bs-gray-100); 
}
.kde-entre--passed{
  border: none; background: var(--bs-gray-200);
  color: var(--bs-gray-500);
}
.kde-entre--passed .kde-entre-status{
  border: 2px solid #198754; 
  background: var(--bs-gray-200);
  display: flex; align-items: center; 
  justify-content: center; color: #198754;
  filter: grayscale(0.5)
}
.show {
    display: block;
}

/*-----------Questions---------------------*/
.questions .form-button{
  width: 100%; max-width: 400px;
}
.questions{
  padding: 30px;
  display: flex; flex-direction: column;
  align-items: center; 
}
.questions>div{
  width: 100%; max-width: 400px;
}
.question-entry{
  margin-bottom: 40px;
}
.question-entry:nth-of-type(2) .radio-wrapper{
  margin-bottom: 30px;
}
.question-heading,.question-sub-heading{
  letter-spacing: -0.04px;
  font: normal 600 24px/28px var(--Helvetica);
  color: #000; margin-bottom: 10px;
}
.question-sub-heading{
  font: normal 700 16px/27px var(--Helvetica);
}
.check-wrapper{
  display: flex; align-items: center; margin-bottom: 10px;
}
.custom-checkbox,.custom-radio{
  display: none; /* Hide the default checkbox */
}
.custom-radio-option{
  margin-bottom: 10px;
}
.custom-checkbox + label,.custom-radio + label {
  position: relative;
  padding-left: 30px; /* Adjust the padding based on your design */
  cursor: pointer; padding-top: 4px;
}

.custom-checkbox + label:before, .custom-radio + label:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px; /* Adjust the width and height based on your design */
  height: 20px;
  border: 2px solid #e6ecf5; /* Green border color */
  border-radius: 50%;
  background-color: #fff; /* White background color */
  display: flex; text-align: center; justify-content: center;
}

.custom-checkbox:checked + label:before, .custom-radio:checked + label:before {
  content: '✔'; /* Unicode check mark symbol */
  font-size: 14px; /* Adjust the font size based on your design */
  line-height: 20px; /* Center the check mark vertically */
  text-align: center;
  color: var(--base-color); /* Green check mark color */
}
.k-input-error{
  position: relative;
  top: -30px; display: none
}


/*--------------Upload document action section-------------*/
.documents{
  padding: 30px; 
  display: flex; flex-direction: column;
  align-items: center;
  display: none;
}
.documents>div{
  width: 100%; max-width: 400px;
}
.da-heading{
  font: normal 100 20pt/1.1 var(--Helvetica);
  text-align: center; color: var(--bs-gray-900);
  margin-bottom: 10px;
}
.da-sub-heading{
  font: normal 100 14pt/1.1 var(--Helvetica);
  margin-bottom: 20px; color: var(--bs-gray-900)
}
.document-action{
  display: flex; align-items: center; justify-content: space-between;
  width: 100%;
  max-width: 384px;
  border: 1px solid #EBEBEB;
  border-radius: 5px;
  background-color: #FFFFFF;
  padding: 16px 8px;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 20px;
  gap: 10px;
}
.documents-dai{/*Document action icon*/
  width: 42px; height: 42px;
}
.international-passport-icon{
   background: url("../component_media/passport_icon.svg") center/cover no-repeat; 
}
.id-card-icon{
   background: url("../component_media/id_card_icon.svg") center/cover no-repeat; 
}
.action-tag{
  margin-bottom: 10px;
}
.action-sub-tag{
  font-size: 12px;
}
.k-next-arrow{
  background: url("../component_media/dropdown_icon.svg") center/cover no-repeat;
  width: 20px; height: 20px; transform: rotate(-90deg);
}

/*-----------documents-um------------*/
.documents-um{/*document upload medium*/
  padding: 30px;
  display: flex; flex-direction: column;
  align-items: center;
  display: none;
}
.documents-um>div{
  width: 100%; max-width: 400px;
}
.document-prev-butt{
  width: 35px; height: 35px;
  cursor: pointer;
}
.document-prev-butt::before {
  content: " \279c";
  transform: rotate(180deg);
  display: inline-block;
  vertical-align: middle;
  position: relative;
  border-width: 0;
  margin-right: .5rem; font-size: 25px;
  color: var(--bs-gray-700); 
}
.documents-um .da-heading{
  color: #444;
  font-size: 24px;
  line-height: 2rem; margin-bottom: 40px;
}

/*Custom file picker*/
.k-upload-butt-wrapper {
  position: relative; 
  margin-bottom: 20px
}
.k-upload-butt{
  background-color: #f5f5f5;
  border-radius: 4px;
  border: 0;
  box-shadow: 0 .1rem .2rem #0003;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.2;
  padding: 1.4rem 1.6rem;
  display: flex; gap: 20px; align-items: center;
  width: 100%; margin-bottom: 10px;
}
.k-upload-butt:hover {
    background-color: var(--bs-gray-300);
}
.file-name {
    width: 100%;
}
.k-upload-butt .ub-icon{
  background: url("../component_media/file_upload_icon.svg") center/contain no-repeat;
  width: 25px; height: 25px;
}
.passport-upload-error,.id-upload-error{
  padding-bottom: 10px; 
  text-align: center; display: none;
}

/*success message*/
.k-success-message{
  width: 100%;max-width: 400px; 
  margin: auto; padding: 40px 0px 30px 0px;
  display: none;
}
.k-success-message .check-mark{
  font-size: 50px; color: green;
  text-align: center; width: 100px; height: 100px;
  border: 1px solid; border-radius: 50%; 
  display: flex; justify-content: center; align-items: center;
  margin: auto; margin-bottom: 15px;
}
.k-success-message .check-mark+div{
  color: var(--bs-gray-800); 
}
.ksm-header{
  font: normal 600 25px/1.1 var(--Helvetica);
  margin-bottom: 30px; color: #343a40;
  text-align: center;
}




@media screen and (min-width:712px){
    /* -----------top bar--------------- */
  .kyc-pane{
    margin: auto;
    display: flex; margin-top: 200px; gap: 20px;
    width: 100%; max-width: 820px;
  }
  .kcd{
    width: 210px;
  }
  .kdt{
    display: none;
  }
  .kde{
    display: block;
  }
  .kyc-interations{
    position: relative;
  }
  .kyc-note{
    display: block; top: -50px;
    position: absolute; padding-left: 10px;
  }
}