@import url("../base.css");


*{
    font-family: 'Gilroy' sans-serif;
    --bs-body-color: #FFF;
    --bs-body-bg: #1f1f1f;
}
body{
    position: relative;
}
.sec-tion{
    width: 100%; max-width: 1200px; margin: auto;
    margin-bottom: 100px; 
}
.heading{
    font-family: var(--Gilroy-Bold);
    color: #1f1f1f; margin-bottom: 20px;
}
.subheading{
    font-size: 17px; line-height: 29px;
    color: #57575c; font-family: var(--Gilroy-Regular);
    margin-bottom: 20px;
}

a{
    text-decoration: none;
}

.butt a{
    text-decoration: none;
    color: #0a0a0a;
}
.l1-heading{
    font-size: 40px; line-height: 59px; 
    font-family: var(--Gilroy-Bold);
}


.no-wrap912{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 30px;
}
.no-wrap912:not(.hero-sect) {
    width: 95%; box-shadow: 0 4px 20px rgba(0,0,0,.1);
    border-radius: 20px;
}
.hero-mini-tag{
    color: #00615e; font-size: 13px; line-height: 15px; 
    font-family: var(--Gilroy-Regular); margin-bottom: 10px;
}
.start-now-button{
    padding: 8px 16px;
    cursor: pointer;
    font-style: normal;
    letter-spacing: .3px;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    border-radius: 4px;
    text-transform: uppercase;
    background-color: #81d8d0;
}
.start-now-button:hover {
    background-color: #59ded2;
}
.start-now-button .inner-butt{
    font-size: 16px; line-height: 24px;
}


/*-------------------- top bar ----------------------*/
.home-top-bar, .top-bar{
    padding: 40px 30px; justify-content: space-between;
}
.home-top-bar{
    margin-top: 50px;
}
.home-top-header a, .top-header{
    font-size: 30px;
    color: var(--base-color);
    font-family: var(--stencil-display);
}
.header-nav-links-wrapper{
    display: flex; justify-content: space-between;
}
.header-nav-links-wrapper>div{
    position: relative;
}
.header-nav-links-wrapper h4:hover {
    color: var(--base-color);
    filter: invert(48%) sepia(61%) saturate(961%) hue-rotate(137deg) brightness(91%) contrast(100%);
}

.dropdown-header::after{
    display: inline-block;
    content: url("../component_media/dropdown_icon.svg");
    position: absolute;
    filter: invert(42%) sepia(9%) saturate(207%) hue-rotate(182deg) brightness(88%) contrast(85%);
}
.header-nav-links-wrapper ul{
    display: none; list-style-type: none; background: #fff;
    position: absolute; top: 40px;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    padding: 32px;
    width: 400px; flex-wrap: wrap;
    justify-content: space-around;
}
.dropdown::after {
  content: '';
  position: absolute;
  top: -25px;
  left: 10%;
  margin-left: -5px;
  border-width: 12px;
  border-style: solid;
  border-color:  transparent transparent rgba(0,0,0,.15) transparent;
}
#partner-list{
    width: 200px; flex-direction: column;
}
.header-nav-links-wrapper{
    width: 50%; max-width: 400px
}
.header-nav-links-wrapper h4{
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    cursor: pointer;
}

.header-nav-links-wrapper li{
    display: flex; align-items: flex-end; gap: 8px;
    margin-bottom: 20px; font-family: var(--bs-body-font-family);
    flex: 50%;
}
.header-nav-links-wrapper li:hover{
    color: #00615e;
    background: 0 0;
}
.header-nav-links-wrapper a{
    color: var(--bs-gray-700);   
}


.landscape-nav{
    display: none;
}
.landscape-header-button{
    display: none
}



/*
Mobile menu
*/
.home-menu-btn{
    cursor: pointer; margin-top: 10px;
}
.home-menu-close-btn{
    cursor: pointer;
    display: none;
}
.home-menu{
    top: 130px; position: fixed; right: -100%;
    width: 100%; max-width: 330px; background: #FFF;
    padding: 15px; transition: 0.5s;
}
.home-menu .start-now-button{
    width: 100%;
}
.mobile-menu{
    flex-direction: column;  
    width: 100%
}
.mobile-menu>div{
    margin-bottom: -35px; border-top: 1px solid var(--bs-gray-300); padding-bottom: 15px
}
.mobile-menu h4{
    padding-right: 105px;  
}
.mobile-menu .dropdown-header::after {
  padding-left: 72%;
}
.mobile-menu ul{
    position: relative; top: 0; width: 100%; flex-direction: column;
    padding: 0px; border: none;
}
.dropdown::after {
    display: none
}

/*---------------------- hero section -----------------------*/
.hero-sect{
    margin-top: 150px;
}

/*---------------------- perks section -----------------------*/
.about-subheading{
    margin-top: -30px;
}
.perks{
    padding: 30px; margin-bottom: 50px;
}
.l2-heading{
    font-size: 32px; line-height: 35px;  margin-bottom: 50px;
}
.perks .l2-heading{
    margin-bottom: 50px;
}
.perks-tab-wrapper{
    display: flex; flex-direction: column; gap: 20px; 
    align-items: center;
}
.perks-tab{
    background: #fff;
    padding: 24px;
    border-radius: 10px;
    height: 100%;
    word-break: break-word; width: 100%; max-width: 400px;
}
.tab-heading{
    font-size: 24px; line-height: 26px;
    margin-bottom: 10px;
}
.tab-note{
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: #57575c; margin-bottom: 20px;
}
.perks-tab a{
    color: var(--base-color);
}

/*about page styles*/
.about-perk-section-mini-tag, .about-perk-section-heading{
    text-align: center; 
}
.about-medal-perks-tab{
    background: none;
}

/*----------------------  product section -----------------------*/
.products{
    width: 95%; padding: 50px 30px;
}
.products a{
    color: var(--base-color);
}
        
/*----------------------  dark section -----------------------*/
.dark{
  text-align: center;
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
    box-shadow: 0 4px 20px rgba(0,0,0,.1);
  border-radius: 20px; padding: 30px 50px;
    width: 95%; margin-bottom: 100px;
}
.dark .heading{
    color: white;
}
.dark .mini-tag{
    color: rgb(129, 216, 208);
}


/*----------------------  quickie section -----------------------*/
.quickie{
    padding: 30px;
}
.quickie a{
    color: var(--base-color);
}

/*----------------------  platforms -----------------------*/
.platforms{
    background-color: var(--bs-body-bg); padding: 100px 30px;
}
.platforms .mini-tag{
    color: #81d8d0;
}
.platforms .heading{
    color: #FFF;
}
.platforms .subheading{
    color: #FFF;
}

/*----------------------  more-to-explore -----------------------*/
.more-to-explore{
    padding: 30px;
}
.more-to-explore .perks-tab{
    height: 537px;
}
.more-to-explore a{
    color: var(--base-color);
}


/* */
footer{
    background-color: var(--bs-body-bg);
    padding: 70px 30px;
}
.global-broker{
    background-color: #fff; text-align: center; margin-bottom: 20px;
}
footer .start-now-button{
    margin-bottom: 30px;
}
.gateway-option{
    display: flex; justify-content: space-around;
    width: 100%; max-width: 400px; margin: auto;
    flex-wrap: wrap; gap: 10px;
}
.gateway-option img{
     margin-bottom: 20px;
}
.social-links{
    height: 50px; padding-top: 8px;
}
.social-links button{
    background: none; 
}
.social-links img{
    filter: invert(65%);
}
.social-band{
    display: flex; justify-content: center; align-items: center;
    flex-wrap: wrap; margin-bottom: 10px; gap: 0px
}


.footer-links{
    display: flex; justify-content: space-between;
    flex-wrap: wrap; margin-bottom: 20px;
}
.footer-links ul{
    padding: 0px; list-style-type: none;
}
.footer-links h4{
  margin-bottom: 8px; font: 14px;
  line-height: 24px; border-bottom: 1px solid var(--bs-gray-800);
  padding-bottom: 10px;
  color: #fff;
}
.footer-links li{
    color: var(--bs-gray-500);
    font: 400 14px/24px var(--Helvetica);
    letter-spacing: 0; margin-bottom: 10px;
}
.footer-links li a{
    color: var(--bs-gray-500);
}
.footer-links li:hover{
    color: #FFF;
}
/*copy right*/
.legal-doc{
    color: #bfc5ce;
    font: 400 14px/24px var(--Helvetica);
    line-height: 24px;
    line-height: 20px;
    letter-spacing: 0; margin-bottom: 0px;
}
.legal-doc-head{
    text-align: center;    
}



@media screen and (min-width:600px){
    .social-band{
        justify-content: space-between;
    }
}
@media screen and (min-width:912px){
    .home-menu-btn{
        display: none;
    }
    .landscape-header-button{
        display: block;
    }
    .landscape-nav{
        display: flex
    }
    .no-wrap912 {
        flex-wrap: nowrap;
    }
    .perks-tab-wrapper{
        flex-direction: row; justify-content: space-around;
    }
}
@media screen and (min-width:1024px){
    .home-top-header a, .top-header{
        font-size: 50px; 
    }
    .home-top-bar, .top-bar{
        justify-content: space-around;
    }
}