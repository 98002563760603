@import url("../dashboard.css");


/*
#referral-content{
  display: block;
}
*/
.tab-body{
  padding-bottom: 1px;
}
.referral-content-banner{
  display: flex; flex-direction: column-reverse;
  border-radius: 5px; margin-bottom: 50px;
}
.rcb-textual{
  padding: 30px;
  background: #273A47;
  border-bottom-right-radius: 10px; border-bottom-left-radius: 10px;
}
.rcb-subtext{
  margin-bottom: 30px;
}
.rcb-link-wrapper{
  height: 37px;
  border-radius: 18.5px;
  background-color: #536A78;
  padding-right: 3px; width: 100%; max-width: 450px;
}
.rcb-link-wrapper{
  display: flex; padding: 5px 2px 5px 5px;
  margin-bottom: 30px; align-items: center;
  justify-content: space-between;
}
.rcb-link-wrapper>div{
  overflow: hidden;
  text-wrap: nowrap; border-radius: inherit;
  color: white; display: flex; align-items: center;
  padding-left: 5px; font-size: 12px;
}
.rcb-link-wrapper>button{
  background-color: #445A68;
  font-size: 10px;
  flex-basis: 50px;
  text-wrap: nowrap;
  border-radius: inherit; padding: 10px;
  color: #FFF;
}
.rcb-bottom-text{
  font-size: 13px;
}
.rcb-media{
  background:  #273A47 url("../../component_media/refer-a-friend.png") right/cover no-repeat;
  border-top-right-radius: 10px; border-top-left-radius: 10px;
  /*
  background-size: 288px 265px; 
  */
  min-height: 200px;
}

/*--------Tab Section-------------*/
.total-invited-friends-icon{
    background: url("../../component_media/total_invited_friends.svg") center/contain no-repeat;
}
.rewarded-friends-icon{
   background: url("../../component_media/rewarded-friends.svg") center/contain no-repeat;
}
.total-earnings-icon{
   background: url("../../component_media/total-earning.svg") center/contain no-repeat;
}
.rewards-in-progress-icon{
   background: url("../../component_media/reward_in_progress.svg") center/contain no-repeat;
}
.referral-content .tab-head{
  flex-wrap: wrap;
}
.rtha{/*Referral tab head action*/
  color: #4B5156; background: var(--bs-gray-200);
  font-family: var(--roboto-font); border: none;
  font-weight: normal; display: flex;
  justify-content: space-between; align-items: center; padding: 2px 3px;
  min-height: 45px;
}
.rtha-sub{
  margin-right: 5px; padding-right: 5px;
  font-size: 12px; min-width: 75px;
}
.rtha-sub:hover{
  font-weight: 500; background: none;
}
.rtha-sub:not(:last-child){
  border-right: 1px solid var(--bs-gray-500);
}
.default-rtha-sub{
  height: 90%; 
  border-radius: inherit; 
  background: white; 
  border-right: none !important;
}
.default-rtha-sub:hover{
  background: white;
}

.rbht{
  border: 3px solid var(--bs-gray-200); min-height: 45px;
  padding-top: 10px; padding-bottom: 10px;
}
.rbht>div{
  font-size: 12px; font-family: var(--roboto-font);
  color: #191C1F; font-weight: 500;
}
.rbht-sub{
  padding: 0px 5px;
}
.rbht-noeb{
  border-right: 1px solid var(--bs-gray-500);
}

.referral-badge-entry{
  flex-direction: column; align-items: flex-start;
}
.badge-icon{
  width: 35px; height: 35px;
  margin-bottom: 10px;
}
/*
.bi-starter{
  background: url("../../component_media/referral_starter_grayscale.svg") center/contain no-repeat;
}
*/
/*color: var(--bs-dark) When valid  --  When invalid color: #8E8D8E; */
.referral-badge-entry{
  color: #8E8D8E;
}
.referral-badge-entry-valid{
  color: var(--bs-dark)
}
.badge-heading{
  line-height: 14px;
  font-weight: 500; font-size: 16px;
  margin-bottom: 15px; /*color: var(--bs-dark)*/
}
.badge-note{
  font-size: 13px; 
  margin-bottom: 15px;
}
.badge-progress{
  width: 100%; display: flex; align-items: center;
}
.bp-progress-bar{
  flex: 100%; height: 10px; border-radius: 5px;
  background-color: #f5f5f5; margin-right: 3px;
}
.bp-progress-bar>div{
  border-radius: inherit; width: 0%;
  background: var(--base-color); height: 90%
}
.bp-count{
  font-size: 12px; 
}
/*badge images*/
.bi-starter{
  background: url("../../component_media/referral_starter_grayscale.svg") center/contain no-repeat;
}
.bi-influencer{
  background: url("../../component_media/referral_influencer_grayscale.svg") center/contain no-repeat;
}
.bi-expert{
  background: url("../../component_media/referral_expert_grayscale.svg") center/contain no-repeat;
}
.bi-master{
  background: url("../../component_media/referral_master_grayscale.svg") center/contain no-repeat;
}
.bi-champion{
  background: url("../../component_media/referral_champion_grayscale.svg") center/contain no-repeat;
}
/*valid entry*/
.valid-referral-badge{
  color: var(--bs-dark)
}
.valid-referral-badge .bi-starter{
  background: url("../../component_media/referral_starter.svg") center/contain no-repeat;
}
.valid-referral-badge .bi-influencer{
  background: url("../../component_media/referral_influencer.svg") center/contain no-repeat;
}
.valid-referral-badge .bi-expert{
  background: url("../../component_media/referral_expert.svg") center/contain no-repeat;
}
.valid-referral-badge .bi-master{
  background: url("../../component_media/referral_master.svg") center/contain no-repeat;
}
.valid-referral-badge .bi-champion{
  background: url("../../component_media/referral_champion.svg") center/contain no-repeat;
}





@media screen and (min-width:1024px){
  .referral-content-banner{
    flex-direction: row; min-height: 300px;
  }
  .rcb-textual{
    border-bottom-right-radius: 0px; border-bottom-left-radius: 10px;
    border-top-left-radius: 10px; width: 60%; 
    display: flex; flex-direction: column; justify-content: center;
  }
  .rcb-media{
    width: 40%;
    border-top-right-radius: 10px; border-top-left-radius: 0px;
    border-bottom-right-radius: 10px; 
  }
}