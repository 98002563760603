@import url("../base.css");

.client-area main{
    border: 1px solid rgba(0,0,0,0);
    margin-top: 150px;
}
.client-area .top-bar{
    position: relative;
    padding-bottom: 20px;
}
.top-bar{
    border-bottom: 1px solid #DBDBDB; height: 85px; justify-content: space-between;
    padding: 0px 20px 0px 20px;
}
.top-header{
    font-size: 40px;
}
.menu-btn{
    cursor: pointer;
    background: url("../component_media/menu_icon.svg") center/contain no-repeat;
    width: 27px; height: 19px;
}
.user-menu-btn{
    cursor: pointer;
    background: url("../component_media/user_menu.svg") center/contain no-repeat;
    min-width: 5px; height: 22px;
}
.name-toggle{
    display: none
}
.dashboard-pane-indicator{
    font-size: 16px; position: absolute;
    bottom: 8px;
}
.dashboard-pane-indicator>span:nth-of-type(1){
    margin-right: 5px;
    color: var(--base-color);
    color: #445A68;
    color: var(--bs-gray-600);
}
.dashboard-pane-indicator>span:nth-of-type(2){
    color: var(--bs-gray-500);
}
.locator{
    color: hsla(33.25, 86.09%, 59.41%, 1);
    color: #445A68;
    color: hsla(33.25, 86.09%, 49.41%, 0.5);
    color: var(--bs-gray-500);
}

/*----------------- Menu -----------------*/
.menu .top-header{
    display: none;
}
.menu {
    position: fixed;
    top: 125px;
    left: 0px;
    width: 100%; left: -100%;
    height: 100%;
    background-color: #f4f4f4;
    padding-top: 20px;
    transition: 0.3s;
    z-index: 52;
    overflow: hidden auto;
}

.menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu ul li {
    padding: 15px; display: flex; align-items: center; gap: 10px;
    cursor: pointer;
    text-decoration: none; font-family: var(--roboto-font);
    color: black;
    font-size: var(--small-font);
    cursor: pointer;
}
.menu ul li:nth-of-type(1) {
    border-left: 5px solid var(--base-color);
}
.menu ul li:last-child {
    color: var(--base-color);
}
.menu ul li:hover {
    filter: invert(48%) sepia(61%) saturate(961%) hue-rotate(137deg) brightness(91%) contrast(100%);
    color: var(--base-color);
}

.list-icon{
    width: 24px; 
    height: 24px;
}
.list-icon1{
    background: url("../component_media/menu_dashboard_icon.svg") center/contain no-repeat;
}
.list-icon2{
    background: url(../component_media/menu_deposit_icon.svg) center/contain no-repeat;
}
.list-icon3{
    background: url("../component_media/menu_withdrawal_icon.svg") center/contain no-repeat;
}
.list-icon4{
    background: url("../component_media/transfericon.png") center/contain no-repeat;
}
.list-icon5{
    background: url("../component_media/menu_report_icon.svg") center/contain no-repeat;
}
.list-icon6{
    background: url("../component_media/menu_analysis_icon.svg") center/contain no-repeat;
}
.list-icon7{
    background: url("../component_media/menu_platforms_icon.svg") center/contain no-repeat;
}
.list-icon8{
    background: url("../component_media/menu_referral_icon.svg") center/contain no-repeat;
}



/*----------------- User Menu -----------------*/
.user-menu{
    position: fixed;
    right: -100%; 
    top: 125px;
    width: 100%; max-width: 320px;
    background-color: #FFF;
    padding-top: 20px;
    transition: 0.3s;
    font-family: var(--roboto-font);
    border: 1px solid #DBDBDB;
    border-radius: 10px; transition: 0.5s;
    box-shadow: 0 4px 20px rgba(0,0,0,.1);
    z-index: 51;
}
.user-menu a{
    color: var(--bs-dark)
}
.user-menu h4{
    padding: 10px 25px;
    color: #191C1F;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    white-space: normal;
    margin: 0;
    width: 100%;
}
.user-menu ul{
    list-style-type: none; padding: 15px 30px;
    border-bottom: 1px solid #DBDBDB; border-top: 1px solid #DBDBDB;
    margin: 0px;
}
.user-menu ul>li{
    margin-bottom: 20px;
}
.user-menu .user-menu-footer{
    color: var(--base-color); padding: 15px 25px;
}
.user-menu .user-menu-footer>div{
    cursor: pointer; display: flex;
    
}
.user-menu li{
    cursor: pointer;
}
.empty-menu-btn{
    width: 27px; height: 19px; display: none;
}
.menu-close-btn{
    display:none; cursor: pointer;
    background: url("../component_media/menu_close.svg") center/contain no-repeat;
    width: 19px; height: 19px;
}


/*----------------- Top Banner -----------------*/
/*kyc notice*/
.kyc-notice{
    margin-top: 50px;
    background: #FDF8E1; padding: 20px 10px;
    display: flex; flex-direction: column;
    gap: 10px; align-items: center; 
}
.kn-text{
    font: normal 400 16px/27px var(--Helvetica);
    color: #000; display: flex; gap: 10px;
    justify-content: center;
}
.kn-text>div:nth-of-type(1){
    background: url("../component_media/important_notice.svg") center/contain no-repeat;
    width: 25px; height: 25px;
}
.kn-link{
    text-align: center;
    letter-spacing: 0.05em;
    cursor: pointer;
    white-space: nowrap;
    width: 100%; max-width: 200px;
    margin: auto;
}
.kn-link button{
    font: normal 400 16px/27px var(--bs-body-font-family);
    background: #FFCF00; 
    padding: 10px; border-radius: 5px;
    width: 100%; height: 100%;
}
.kn-link a{
  color: #1F1F1F; text-decoration: none;
}

/*referral-notice*/
.referral-notice{
    background: #273A47 url("../component_media/refer-a-friend.png") center/cover no-repeat;
    border-radius: 5px; width: 100%; padding: 20px;
    display: flex; font-family: var(--roboto-font); 
    margin-top: 50px;
    margin-bottom: 40px;
    justify-content: space-around; align-items: center; gap: 20px;
    flex-direction: column;
}
.banner-head{
  font-size: 22px; color: white;
  font-weight: 400;
  letter-spacing: 0; 
  line-height: 24px; margin-bottom: 10px;
}
.banner-note{
    color: white;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  font-weight: 300;
}
.banner-cta{
    background: #00ADA8;
  font-size: 16px; 
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  cursor: pointer;
  color: white;
  text-align: center;
  padding: 14px;
  border-radius: 5px;
}
.banner-cta:hover {
  box-shadow: 0 3px 5px 0 #9CADB4 !important;
}

/*-----------welcome message-------------*/
.user-welcome-message{
    font-size: 20px; 
    color: var(--bs-gray-300); 
    z-index: 50;
}
.user-welcome-message>span{
   color: var(--bs-gray-100);/*#F79D38*/;
}
.landscape-uwm{
    display: none;
}
.mobile-uwm{
    position: fixed; background: #445A68; padding: 10px; 
    border-radius: 10px 10px 10px 0px; 
    left: 10px;
}

/*----------------- dashboard content -----------------*/
.dashboard-content{
    margin-top: 0px;
 }

/* Account summary*/
h3{
   color: var(--gray); font-family: var(--roboto-font);
  font-size: 16px;
  line-height: 19px;
  font-weight: 500; padding-left: 3px;
}
h4{
  color: var(--gray); font-family: var(--roboto-font);
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}
.tab-head{
    display: flex; align-items: center; justify-content: space-between;
    margin-bottom: 10px;
}
.tab-body{
    background: white; padding: 20px; border-radius: 4px;
}
.tab-head-action{
  height: 14px; font-family: var(--roboto-font); font-style: normal;
  font-weight: 600; font-size: 12px; line-height: 14px;
  color: var(--gray); padding: 10px 16px;
  height: 40px; background: #FFFFFF;
  border: 1px solid #E2E6EA; border-radius: 4px; cursor: pointer;
  display: flex; align-items: center;
}

.account-summary{
    display: flex; flex-wrap: wrap;
    margin-bottom: 50px; justify-content: space-around; 
}

.account-summary-entry{
    display: flex; justify-content: space-between; align-items: center;
    padding: 12px 0px; width: 100%; 
}
.account-summary-entry:not(:last-child) {
  border-bottom: solid 1px #EBEBEB;
}


.account-summary-note>div{
  color: #191C1F; font-size: 14px; margin-top: 12px; font-weight: 500;
}
/*
.account-summary-icon{
}
*/
.token-icon{
   width: 35px; height: 35px;
    background: url("../component_media/token_icon.svg") center/cover no-repeat;
}
.deposit-icon{
    background: url("../component_media/deposit_icon.svg") center/cover no-repeat;
}
.colored-withdrawal-icon{
    background: url("../component_media/colored_withdraw_icon.svg") center/cover no-repeat;
}

/*Recent Activity*/
.recent-activity{
    margin-bottom: 50px;
    background: white; padding: 20px;
    display: flex;  gap: 10px; flex-direction: column;
    overflow: hidden;
}
.recent-activity-entry{
    padding: 12px 0px; width: 100%;
}
.recent-activity-entry:not(:last-child) {
  border-bottom: solid 1px #EBEBEB;
}
.recent-activity-datetime{
  color: var(--gray) !important; font-size: 14px;
  line-height: 16px; font-weight: 500;
  margin-bottom: 15px;
}
.recent-activity-note{
  color: #191C1F; font-size: 14px;
  letter-spacing: -0.05px; line-height: 19px; margin-bottom: 10px;
}
.recent-activity-status{
  font-weight: 500; font-size: 13px; color: #191C1F
  /*
  */
}
.approved{color: #2AC294 !important;} 
.pending{ color: #F79D38 !important;}
.rejected{color: #ce0000 !important;}
.untouched{color: #191C1F !important;}

/* Wallet Accounts*/  
.wallet-account-entry{
    display: flex; flex-direction: column;
}
.wallet-account-entry>div{
    margin-bottom: 20px;
}
.account-name{
    margin-bottom: 10px;
}
.usd-tag{
  background-color: #EDF6F1; color: #689E80; width: 40px;
  height: 25px; display: inline-block; font-size: 13px;
  line-height: 15px; font-weight: 600; font-style: normal;
  font-family: var(--roboto-font); padding: 5px 8px; user-select: none;
  border-radius: 3px;
}
.account-name-title{
  color: var(--base-color); font-family: var(--roboto-font);
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  margin: 0 10px;
}
.balance{
    display: flex; justify-content: space-between; align-items: center;
}
.balance-tag{
    font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
  color: #4B5156;
  text-transform: capitalize;
}
.balance-amount{
  color: #191C1F; font-size: 14px; font-weight: 500;
}

.account-action{
    display: flex; gap: 6px;
}
.account-action>div{
    flex:1; display: flex; 
    font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  background: #FFFFFF;
  color: black;
  border: 1px solid #E8E8E8;
    padding: 21px 0;
    width: 100%;
    justify-content: space-around;
    border-radius: 4px;
  transition: 0.5s;
  align-items: center;
  /*for disabled
    filter: grayscale(1)
    color: #A2A3A4;
    cursor: not-allowed;
    pointer-events: auto;
   */
}
.account-action>div:hover{
    border: 1px solid var(--base-color);
}
.small-icon{
    width: 16px; height: 16px; 
}
.small-deposit-icon{
    background: url("../component_media/small_deposit_icon.svg") center/contain no-repeat;
}
.small-transfer-icon{
    background: url("../component_media/small_transfer_icon.svg") center/contain no-repeat;
}
.small-withdraw-icon{
    background: url("../component_media/small_withdrawal_icon.svg") center/contain no-repeat;
}






/*---------------------------deposit content----------------------------*/
.dashboard-sect{
    margin-bottom: 100px;
    display: none;
}
.deposit-content .pre-button-note{
    display: none;
}
.dash-header-note{
    display: flex; align-items: center; gap: 10px;
    color: #191C1F;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
}
.deposit-bonus-note::before{
    content: url("../component_media/promo_icon.svg");
}
.reports-pre-note::before{
    content: url("../component_media/menu_report_icon.svg");
    filter: invert(48%) sepia(61%) saturate(961%) hue-rotate(137deg) brightness(91%) contrast(100%);
}
.withdrawal-pre-note::before{
    content: url("../component_media/menu_withdrawal_icon.svg");
    filter: invert(48%) sepia(61%) saturate(961%) hue-rotate(137deg) brightness(91%) contrast(100%);
}


.dash-sect-header{
    margin-bottom: 20px;
}


.dashboard-sect-form-wrapper{
    width: 100%; max-width: 500px; margin: auto;
    overflow: hidden; position: relative;
    min-height: 280px; padding: 20px;
}
.dashboard-sect-form .form-input-wrapper{
    margin-bottom: 20px;
}
.dashboard-sect-form h4{
    margin-bottom: 10px;
}
.dashboard-sect-form label{
   font-weight: 500;
   line-height: 19px;
   color: #4B5156;
   font-size: 13px;
}
.dash-sect-amount-wrapper{
    display: flex; padding: 0px; margin-bottom: 5px
}
.dash-sect-amount-wrapper input{
    padding-left: 10px;
}
.dash-sect-amount-wrapper input:focus{
    border: 1px solid;
}
.deposit-usd-tag{
  background-color: #F5F6F7;
  color: #191C1F;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.05px;
  line-height: 16px;
  height: 100%; width: 18%;
  display: flex; align-items: center; justify-content: center;
}
.dashboard-sect .input-error{
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
}
.pre-button-note{
  color: #191C1F;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px; margin-bottom: 30px;
}
.dashboard-sect .bottom-note{
    color: #191C1F;
    font-size: 13px;
    border-top: 1px solid var(--input-wrapper-border);
    padding: 10px 0px;
}
.deposit-form{
     transition: 0.5
}
.dfie{
    padding-top: 10px; margin-left: -20px;
}

/*--------------------Withdrawal-------------------------*/
.input-message{
    /*
    */
    display: none;
}
.withdrawal-input-amount-message{
 font-size: 14px; color: #F79D38;  
}
.input-message-patch{
    color: var(--bs-gray-800); font-size: 14px;
}


/*--------------------Payment option -------------------------*/
.pay-selection-dropdown-wrapper{
    width: 100%; margin: auto;
    position: absolute; left: 100%;
}
.psdw-back-button{
    cursor: pointer;
}
.psdw-back-button:before{
  content: " \279c";
  transform: rotate(180deg);
  display: inline-block;
  vertical-align: middle;
  position: relative;
  border-width: 0;
  margin-right: .5rem;
}
.pay-selection-dropdown-wrapper h2{
    color: var(--bs-gray-800)
}
.payment-dropdown{
    width: 100%; 
    position: relative; margin-bottom: 50px;
}
.custom-dropdown{
    width: 100%;
}
.dropbtn {
    background-color: #fff;
    color: var(--bs-gray-800);
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    display: flex; justify-content: space-between;
    width: 100%; margin-bottom: 5px;
}
.dropdown-toggle {
    background-color: #fff;
    color: var(--bs-gray-800);
    padding: 10px;
    font-size: 14px;
    border: 1px solid  var(--input-wrapper-border);
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    display: flex; justify-content: space-between;
    align-items: center;
    width: 100%; margin-bottom: 5px; height: 50px;
}
.dropdown-toggle:focus {
    border: 1px solid;
}


.dropbtn > .dropbtn-drop-icon{
    background: url("../component_media/dropdown_icon.svg") center/cover no-repeat; 
    width: 20px; height: 20px; 
}
.dropdown-toggle-icon{/*new-dropdown*/
    background: url("../component_media/dropdown_icon.svg") center/cover no-repeat; 
    width: 20px; height: 20px; 
}

/* Style the dropdown content (hidden by default) */
.dropdown-content {
    display: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    z-index: 1;
    width: 100%;
    box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.06);
    padding: 0px 10px 0px 10px;
}
.dropdown-entries {/*new dropdown*/
    display: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    z-index: 1;
    width: 100%;
    box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.06);
    padding: 0px 10px 0px 10px;
}
.amd1{
    font-weight: 600; color: var(--bs-gray-800)
}
.amd2{
    font-weight: 600; color: var(--bs-yellow)
}

/* Style the dropdown links */
.dropdown-content li {
    color: #000; list-style-type: none;
    padding: 10px;
    text-decoration: none; cursor: pointer;
    padding: 15px 10px;
}
.dropdown-content li:not(:last-child){
    border-bottom:  1px solid var(--bs-gray-300);
}
.dropdown-content li:hover{
    background-color: #f0f0f0;
}

.dropdown-entre {/*new dropdown*/
    color: #000; list-style-type: none;
    padding: 10px;
    text-decoration: none; cursor: pointer;
    padding: 15px 10px;
}
.dropdown-entre:not(:last-child){/*new dropdown*/
    border-bottom:  1px solid var(--bs-gray-300);
}
.dropdown-entre:hover{/*new dropdown*/
    background-color: #f0f0f0;
}

/* Show the dropdown content when the dropdown button is clicked */
.show {
    display: block;
}


.option-pay-wrapper{
    width: 100%; max-width: 400px; min-height: 420px;
    background: white; box-shadow: 0 4px 20px rgba(0,0,0,.1);
    border-radius: 10px; padding: 30px; display: flex;
    flex-direction: column; align-items: center; justify-content: center;
    display: none;
}
.option-pay-wrapper>div{
    width: 100%; max-width: 95%;
}
.pay-wrapper-heading{
  color: var(--bs-gray-700); font-family: var(--roboto-font);
  font-size: 20px; text-align: center;
  line-height: 19px;
  font-weight: 500; margin-bottom: 30px;
}
.zelle-img{
    background: url("../component_media/zelle.jpeg") center/cover no-repeat;
}
.pay-img{
    height: 150px; border-radius: 10px; margin-bottom: 30px;
}
.cashapp-img{
    background: hsl(130, 87%, 43%) url("../component_media/cashapp.jpeg") center/contain no-repeat;
}
.bitcoin-img{
    background: url("../component_media/bitcoin.png") center/contain no-repeat;
}
.usdt-img{
    background: url("../component_media/usdt.png") center/contain no-repeat;
}

.content-to-copy{
    display: flex; background: none;
    justify-content: space-between;
    gap: 3%; flex-wrap: wrap; align-items: flex-start;
}
.transfer-to-no h4{
    font-size: 14px; color: var(--base-color);
}
.copy-button,.copy-content{
    background: white; box-shadow: 0 4px 20px rgba(0,0,0,.1);
    text-align: center; padding: 10px; border-radius: 5px; 
}
.transfer-to-no{
    margin-bottom: 30px;
}
.copy-button{
    width: 80px; cursor: pointer;
}
.copy-content{
    flex: 1; margin-bottom: 10px; overflow: hidden;
}
.option-foot-note{
    font-size: 13px;
}
.payed{
    background: var(--base-color); margin: auto; margin-bottom: 20px; width: 50%;
    padding: 10px; border-radius: 20px; color: white;
    display: none;
}
.pay-display-wrapper>div{
    margin: auto;
}


/*---------------------------platform content----------------------------*/
.mqtrader-platform-wrapper{
    width: 100%; max-width: 320px;
}
.platform-wrapper-parentII{
    display: flex; justify-content: center
}
.mq-preview{
    background: url("../component_media/web_platform_image.svg") bottom/80% no-repeat,
        linear-gradient(180deg, #CDD2D6 0%, #A8B5BF 100%);
    height: 200px; border-radius: 4px;
}
.mqtrader-platform-wrapper h2{
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.05px;
  line-height: 19px;
  text-align: center;
  font-family: var(--roboto-font);
}
.mqtrader-platform-wrapper h2::after{
    content: "New";
    font-size: 11px;
    color: var(--base-color);
    margin-left: 10px;
}
.mq-text{
  min-height: 200px;
  border: 1px solid #DADBDC;
  border-radius: 15px 15px 6px 6px;
  background-color: #FFFFFF;
  box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.06);
  padding: 20px 16px 15px;
  margin-top: -20px;
}
.mq-text p{
    color: #4B5156;
    font-size: 13px;
    letter-spacing: 0.24px;
    line-height: 15px;
}
.mq-text ul{
  gap: 10px;
  background: #F5F6F7;
  border-radius: 3px;
  padding: 20px 30px;
   font-size: 15px;
}



/*-----------------------analysis--------------------*/
.analysis-type{
    margin-bottom: 50px;
}
.analysis-atsc{/*analysis type sub cointainer*/
    display: flex; justify-content: space-evenly;
    flex-wrap: wrap;
}
.analysis-ath{
    font-size: 25px; margin-bottom: 30px;
}
.analysis-type-sub-container{
    display: flex;
}
.analysis-entry{
    width: 100%; max-width: 500px; border-radius: 20px;
    background: #FFF; padding: 50px 30px; box-shadow: 0 4px 20px rgba(0,0,0,.1);
    margin-bottom: 20px;
}
.analysis-entry-title{/*analysis entry title*/
    font-size: 18px; margin-bottom: 30px; 
}
.analysis-entry-title a:after{
  content: " \279c";
  transform: rotate(-45deg);
  display: inline-block;
  vertical-align: middle;
  position: relative;
  border-width: 0;
  margin-left: 5px;
  margin-top: -3px;
  color: var(--bs-gray-700);
}
.analysis-entry-title a{
    color: var(--base-color); text-decoration: none;
}
.analysis-aei{/*analysis entry image*/
    height: 250px; margin-bottom: 20px;
}
.analysis-aei img{
    border-radius: 20px;
}
.analysis-aeapd{/*analysis-entry-author-published-date*/
    display: flex; justify-content: space-between; flex-wrap: wrap;
    margin-bottom: 20px;
}
.analysis-aeapd>div{
    font-size: 14px; padding: 10px 5px;
    color: var(-bs-dark); border-radius: 2px;
    margin-bottom: 10px;
}
.analysis-aeapd-author{
    background: rgba(255, 193, 7, 0.5); 
}
.analysis-aeapd-published-date{
    background: rgba(253, 126, 20, 0.5);   
}
.analysis-aet{ /*analysis-entry-tiker*/
    display: flex; flex-wrap: wrap;
    gap: 20px;
}
.analysis-aet-entry{
    font-size: 14px; padding: 10px 5px;
    color: white; border-radius: 2px;
    background: #20c997; min-width: 50px;
    text-align: center; margin-bottom: 10px;
}
.analysis-loader{
    text-align: center;
    font-size: 20px; color: var(--bs-gray-700)
}


/*Document sect*/
.documents-dihi{
    width: 24px; height: 24px; 
    background: url("../component_media/id-card.png") center/contain no-repeat;
    filter: invert(48%) sepia(61%) saturate(961%) hue-rotate(137deg) brightness(91%) contrast(100%);
}
.documents-tab-body{
    margin-bottom: 50px;
}
.documents-sect .dash-id-header-note{
    padding-bottom: 10px;
}
.documents-entry-wrapper{
    margin-bottom: 0px !important; 
}
.documents-entry{
    flex-direction: column
}
.document-entry-header{
    line-height: 14px;
    font-weight: 500; font-size: 16px;
    margin-bottom: 15px;
}
.document-sub-entry{
    font-size: 14px; color: var(--bs-gray-800)
}




@media screen and (min-width:540px){
    /* -----------account summary--------------- */
    .account-summary{
       justify-content: space-between;
    }
    .account-summary-entry{
      max-width: 200px; padding-right: 20px; margin-bottom: 30px;
    }
    .account-summary-entry:not(:last-child) {
      border-bottom: 1px solid white; border-right: 1px solid #EBEBEB;
    }
    .account-action{
        background: none;
    }
    /* -----------wallet account--------------- */
    .wallet-account{
        background: none; padding: 0px;
    }
    .wallet-account-entry>div:not(:last-child){
        padding: 20px;
    }
    .wallet-account-entry>div:not(:nth-child(2)){
        margin-bottom: 0px;
    }
    .balance{
        justify-content: initial; gap: 20px;
    }
    .account-name,.balance{
        background: white;
    }
    .account-action{
        flex-direction: column;
    }
    .account-action>div{
        justify-content: center; gap: 10px;
    }
}
@media screen and (min-width:712px){
    /* -----------kyc notice--------------- */
    .kyc-notice{
        flex-direction: row;
    }
}
@media screen and (min-width:800px){
    /* -----------kyc notice--------------- */
    .referral-notice{
        justify-content: space-between;
    }

    /* -----------activity entry--------------- */
    .recent-activity-entry{
        display: flex; justify-content: space-between; align-items: center;
    }
}
@media screen and (min-width:912px){
    /* -----------pre dashboard content--------------- */
    .dashboard-pane-indicator{
        left: 305px
    }
    .user-welcome-message{
       color: var(--bs-gray-700);
    }
    .user-welcome-message>span{
       color: #F79D38;
    }
    .landscape-uwm{
        display: block; position: absolute; left: 310px;
        top: 25px;
    }
    .mobile-uwm{
        display: none;
    }
    .menu-btn{
        display: none;
    }
    .top-header{
        display: none;
    }
    .referral-notice{
        flex-direction: row; min-height: 120px;
    }
    .menu .top-header{
        display: block;
    }
    .top-bar{
        background: #F5F6F7; justify-content: flex-end;
    }
    .menu{
        left: 0px; width:300px; background: #FFF;
        z-index: 5; margin-top: -85px; 
    }

    /* -----------user right menu--------------- */
    .user-menu-btn{
        padding: 30px;
        background: none; 
    }
    .user-menu{
        top: 60px; z-index: 1; margin-right: 10px;
    }
    .user-menu h4{
        display: none;
    }
    .user-menu ul{
        border-top: 1px solid #FFF;
    }
    .name-toggle{
        display: block;
        font-family: var(--roboto-font);
        font-size: 14px !important;
        line-height: 16px !important;
        font-weight: 500 !important;
        text-transform: capitalize !important;
    }
    .name-toggle:hover{
        color: var(--base-color);
        filter: invert(48%) sepia(61%) saturate(961%) hue-rotate(137deg) brightness(91%) contrast(100%);
    }
    .name-toggle::after{
        display: inline-block;
        content: url("../component_media/dropdown_icon2.svg");
        position: absolute; padding-left: 5px;
    }

    /* -----------dashboard content--------------- */
    .dashboard-content{
        margin-left: 300px; padding: 30px;
    }

    /*kyc notice*/
    .kyc-notice{
        margin-left: 300px; 
        margin-top: 0px;
        justify-content: space-around;
    }

    /* -----------main--------------- */
    main{
        padding: 0;
    }

}
@media screen and (min-width:1280px){
    /*kyc notice*/
    .kyc-notice{
        padding-left: 5%;
    }
    
    /* -----------wallet account--------------- */
    .wallet-account-entry{
        flex-direction: row; justify-content: space-between
    }
    .wallet-account-entry>div{
        flex: 1;
    }
    .account-name{
        height: 70px; display: flex; align-items: center;
    }
    .balance{
        height: 70px; margin-right: 1%;
    }
    .account-action{
        flex-direction: row; height: 70px; justify-content: space-around;
    }
}
