:root{
  --roboto-font: 'Roboto', sans-serif;
  --small-font: 14px;
  --gray: #4B5156;
  --gray2: rgb(75, 81, 86);
  --base-color: #00AFAB;
  --input-wrapper-border: #EBEBEB;
  --input-wrapper-border2: #ccc;
  --valid-input-border: #2AC294;
  --err-input-border: #ce0000;


  /*Gilroy*/
  --Gilroy-Bold: 'Gilroy-Bold', sans-serif;
  --Gilroy-Heavy: 'Gilroy-Heavy', sans-serif;
  --Gilroy-Light: 'Gilroy-Light', sans-serif;
  --Gilroy-Medium: 'Gilroy-Medium', sans-serif;
  --Gilroy-Regular: 'Gilroy-Regular', sans-serif;

  /*Helvetica*/
  --Helvetica: 'Helvetica', sans-serif;
  --Helvetica-Light: 'Helvetica Light', sans-serif;
  
  /*Unbounded*/
  --Unbounded-font: 'Unbounded', sans-serif;
  
  /*Stencil display*/
  --stencil-display: 'Big Shoulders Stencil Display', sans-serif;
  
  /*stereotones*/
  --stereotones-font: 'Stereotones', sans-serif;
                                                  

  /* Equiti colors*/
  --bs-blue: #0950b8;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14; /*rgba(253, 126, 20)*/
  --bs-yellow: #ffc107; /*(255, 193, 7)*/
  --bs-green: #198754; 
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0950b8;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 9,80,184;
  --bs-secondary-rgb: 108,117,125;
  --bs-success-rgb: 25,135,84;
  --bs-info-rgb: 13,202,240;
  --bs-warning-rgb: 255,193,7;
  --bs-danger-rgb: 220,53,69;
  --bs-light-rgb: 248,249,250;
  --bs-dark-rgb: 33,37,41;
  --bs-white-rgb: 255,255,255;
  --bs-black-rgb: 0,0,0;
  --bs-body-color-rgb: 33,37,41;
  --bs-body-bg-rgb: 255,255,255;
  --bs-font-sans-serif: "Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  --bs-gradient: linear-gradient(180deg,rgba(255,255,255,0.15),rgba(255,255,255,0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;

}
*{
  box-sizing: border-box; font-family: var(--roboto-font);
}
html{
  width:100%; overflow-x: hidden;
}
body{
  width:100%; margin:0% 0px 0px 0%; overflow-x:hidden;
  background: #F5F6F7; position: relative;
}

/*Top Bar*//*sk=>signup kyc*/
.top-bar-wrapper, .sk-top-bar, .home-top-bar{
  position: fixed; top: 0; width: 100%; z-index: 1;
  margin-top: 70px
}
.top-bar-wrapper{
  margin-top: 0px;
}
.sk-top-bar, .home-top-bar, .top-bar, #coinmarketcap-widget-marquee{
  background: white; padding: 1px; display: flex; justify-content: space-between; align-items: center;
  height: 52px; margin: 0px;
  width: 100%; 
}
.sk-top-bar{
  padding: 40px 10px;
  background: #FFF; z-index: 1;
  margin-top: 0px;
}
.signin-top-header, .sk-top-header, .home-top-header, .top-header{
  text-align: center; color: var(--base-color);
  font-family: var(--stencil-display); font-size: 30px;
}

main{
  padding: 20px;
}

/* Language toggle button*/
.language-toggle-button{
  display: flex; align-items: flex-end; cursor: pointer;
}

.ng-binding{
   font-family: var(--roboto-font); font-weight: bold;
   color: var(--gray); text-transform: uppercase;
   font-size: var(--small-font); padding-left: 2px;
   position: relative; top: 1px
}
.ng-binding:hover{
   color: var(--base-color);
}



/*for inputs*/
.input-wrapper{
  width: 100%; border-radius: 5px; border: 1px solid var(--input-wrapper-border);
  height: 45px; padding: 14px 18px; margin-bottom: 20px;
  position: relative;
}
input{
  appearance: none; line-height: 20px; text-align: start;
  background: white; outline: none;  width: 100%; 
  color: #333; font-size: var(--small-font); border: none;
  height: 99%;
}
.input-error{
  color: #ce0000; font-size: 14px;
}

.password-input-wrapper{
  display: flex;
}
.password-status-toggle{
  cursor: pointer; width: 20px; background: none; height: 15px
}
.password-status-toggle>span{
  width: 100%; height: 100%; display: inline-block;
}
.show-password{
  background: url("../src/component_media/show_password.svg") center/contain no-repeat;
}
.hide-password{
  background: url("../src/component_media/hide_password.svg") center/contain no-repeat;
}

button{
  border:none; cursor:pointer;
}
.form-button{
  background: var(--base-color); width: 100%; height: 41px; color: white;
  font-size: var(--small-font); line-height: 20px; border-radius: 5px;
  margin-bottom: 20px; position: relative;
}
.form-button svg{
  position: absolute; left: 5%; filter: grayscale(1); display: none;
}



@media screen and (min-width:1024px){
  .signin-top-header, .sk-top-header, .top-header{
      font-size: 50px; 
  }
  .sk-top-bar, .top-bar{
      justify-content: space-around;
  }
}