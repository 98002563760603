@import url("../base.css");


.hash-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%; 
    padding: 30px;
}

.hash-header {
    text-align: center; color: var(--base-color);
    font-family: var(--stencil-display);
    transition: font-size 1s ease;
    height: 100px;
    width: 100%; font-size: 60px;
    margin-top: -200px; display: block;
}

.hash-header-pulse {
    animation: pulse 1s ease infinite alternate;
}

@keyframes pulse {
    from {
        font-size: 60px;
    }
    to {
        font-size: 70px;
    }
}

.hash-progress-container {
    margin-top: 40px; position: relative;
    width: 250px; overflow: hidden;
    height: 8px;
    background-color: #ddd;
    border-radius: 10px; 
}

.hash-progress {
    width: 100%;
    height: 5px;
    background-color: var(--base-color); border-radius: 3px;
    position: absolute; top: 20%;
    left: -100%;
    animation: moveProgress 2s linear infinite; 
}

/*
.hash-loading {
}
*/

@keyframes moveProgress {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.expired-session-wrapper{
    background: white; padding: 30px;
    border-radius: 10px; display: none;
}
.exp-message{
    margin-bottom: 20px; color: var(--err-input-border);
    font-size: 14px;
}
