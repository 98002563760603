@import url("../base.css");


.sub-form{
    /*
    display: none;
    */
}
.language-toggle-button-wrapper{
    width: 100%; max-width: 570px; margin: auto; padding-top: 10px;
    display: flex; justify-content: right; margin-bottom: 40px;
    margin-top: -70px;
}



.signin-form{
    width: 100%; max-width: 570px; min-height: 525px; margin: auto; background: white;
    border-radius: 5px;  box-shadow: 0 2px 4px 0 #00000008;
    padding: 30px 5%; margin-bottom: 22px;
}
.signin-form>h2{
    color: rgb(25,28,31); text-align: center; font-size: 20px; line-height: 24px;
}
.signin-form>h2+p{
    color: var(--gray2); font-size: var(--small-font); margin-bottom: 50px;
}

.form-input-wrapper{
    margin-bottom: 20px;
}
.input-error{
    margin-top: -10px;
}
.password-input-error{
    margin-top: -40px; margin-bottom: 30px;
}

label{
    color: var(--gray2); font-size: 14px; line-height: 15px;     
}




.remember-me-forgot-password-wrapper{
    display: flex; justify-content: space-between;
    font-size: 13px; margin-bottom: 30px; align-items: center;
}

.custom-checkbox{
  display: none; /* Hide the default checkbox */
}
.custom-checkbox + label{
  position: relative;
  padding-left: 25px; /* Adjust the padding based on your design */
  cursor: pointer; padding-top: 4px;
  font-size: 13px
}

.custom-checkbox + label:before{
  content: '';
  position: absolute;
  top: 0px;
  left: 0;
  width: 18px; /* Adjust the width and height based on your design */
  height: 18px;
  border: 2px solid #e6ecf5; /* Grey border color */
  border-radius: 5px;
  background-color: #fff; /* White background color */
  display: flex; text-align: center; justify-content: center;
}
.custom-checkbox:checked + label:before{
  content: '✔'; /* Unicode check mark symbol */
  font-size: 14px; /* Adjust the font size based on your design */
  line-height: 20px; /* Center the check mark vertically */
  text-align: center;
  color: var(--base-color); /* Green check mark color */
}


.forgot-password{
    color: rgb(0, 87, 159);
}




.different-account-option{
    text-align: center; 
}



.signin-create-account-note{
    text-align: center; font-size: 14px; background: #F5F6F7
}
.signin-create-account-note>span:nth-of-type(1){
    color: var(--gray2);
}
.signin-create-account-note>span:nth-of-type(2){
    color: rgb(0,87,159);
}


@media screen and (min-width:600px){
    /* -----------top bar--------------- */
    .signin-form{
        padding: 30px 90px;
    }
}
